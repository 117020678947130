import { Component, OnInit, Input } from '@angular/core';
import { FAQ } from '@data/classes';

@Component({
  selector: 'faqbox-mobile',
  templateUrl: './faqbox-mobile.component.html',
  styleUrls: ['./faqbox-mobile.component.css']
})
export class FaqboxMobileComponent implements OnInit {
  @Input('faqs') faqs: FAQ[];

  constructor() { }

  ngOnInit() {
  }

}
