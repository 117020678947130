/* Angular core modules */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

/* third party modules */
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';

/* Own Modules */
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

/* Services */


/* Own components */
import { AppComponent } from './app.component';
import { HomeModule } from './modules/home-module/home.module';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    DeviceDetectorModule.forRoot(),
    FlexLayoutModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQ9Vty6gmOmWAOK5ttcb0GsTQ7MV55ODQ'
    }),
    HomeModule,
    AppRoutingModule
  ],
  exports: [
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
