import { Component, OnInit, HostListener, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Article } from '../../../data/classes';

@Component({
  selector: 'mainsuggestbox',
  templateUrl: './mainsuggestbox.component.html',
  styleUrls: ['./mainsuggestbox.component.css']
})
export class MainsuggestboxComponent implements OnInit {
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  @Input("slider_offset_ms") slider_offset: number;
  @Input("slider_timer_ms") slider_timer: number;
  @Input("articles") articles: Array<Article>;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
