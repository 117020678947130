import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CookieType } from '../../data/classes';

@Component({
  selector: 'cookiebanner',
  templateUrl: './cookiebanner.component.html',
  styleUrls: ['./cookiebanner.component.css']
})
export class CookiebannerComponent implements OnInit {
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  @Output() accepted_cookies = new EventEmitter<CookieType[]>();
  @Input() cookies: CookieType[];
  @Input() openSettings: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }

  acceptedCookie(accepted_cookies: CookieType[]): void {
    this.accepted_cookies.emit(accepted_cookies);
    console.log("CookieMainComponent received accepted cookies");
  }
}
