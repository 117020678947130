import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'commentbox-mobile',
  templateUrl: './commentbox-mobile.component.html',
  styleUrls: ['./commentbox-mobile.component.css']
})
export class CommentboxMobileComponent implements OnInit {
  @Input('msg') msg: any;
  @Output('answermsg') answermsg = new EventEmitter<any>();
  msg_passed_time: string = "";

  constructor() { }

  ngOnInit() {
    // calculate passed time and create string
    let actual_date: Date = new Date();
    let milli_difference: number = actual_date.valueOf() - Date.parse(this.msg.msg.postDate);

    if (milli_difference < 60000) {
      this.msg_passed_time = Math.floor(milli_difference / 1000) + " seconds ago";
    }
    else if (milli_difference < 3600000) {
      this.msg_passed_time = Math.floor(milli_difference / 60000) + " minutes ago";
    }
    else if (milli_difference < 86400000) {
      this.msg_passed_time = Math.floor(milli_difference / 3600000) + " hours ago";
    }
    else if (milli_difference < 2592000000) {
      this.msg_passed_time = Math.floor(milli_difference / 86400000) + " days ago";
    }
    else if (milli_difference < 31104000000) {
      this.msg_passed_time = Math.floor(milli_difference / 2592000000) + " month ago";
    }
    else {
      this.msg_passed_time = Math.floor(milli_difference / 31104000000) + " years ago";
    }
  }

  openanswermsg() {
    this.answermsg.emit(this.msg);
  }
}
