import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CookieType, Cookie } from '../../../data/classes';

@Component({
  selector: 'cookiebanner-desktop',
  templateUrl: './cookiebanner-desktop.component.html',
  styleUrls: ['./cookiebanner-desktop.component.css']
})
export class CookiebannerDesktopComponent implements OnInit {

  isCookieSettingsOpen: boolean = false;
  isCookieBannerOpen: boolean = true;
  selectedCookieType: CookieType = null;
  cookies: Cookie[] = null;

  @Output() accepted_cookies = new EventEmitter<CookieType[]>();
  @Input() loaded_cookies: CookieType[];
  @Input() openSettings: boolean;

  constructor() {
  }

  ngOnInit() {
    // select first cookietype
    this.selectCookieType(this.loaded_cookies[0]);

    if (this.openSettings) {
      this.isCookieSettingsOpen = true;
      this.isCookieBannerOpen = false;
    }
  }

  openCookieSettings() {
    this.isCookieSettingsOpen = true;
    this.isCookieBannerOpen = false;
  }

  selectCookieType(cookie_type: CookieType) {
    //  select new cookietype
    this.selectedCookieType = cookie_type;

    // add cookies to array
    this.cookies = this.selectedCookieType.cookies;
  }

  changeCookieTypeEnabled() {
    this.selectedCookieType.is_enabled = !this.selectedCookieType.is_enabled;

    // change all cookie childs
    if (this.selectedCookieType.is_enabled) {
      // activate all cookie childs
      for (let i = 0; i < this.selectedCookieType.cookies.length; i++) {
        this.selectedCookieType.cookies[i].is_cookie_enabled = true;
      }
    }
    else {
      // disable all cookie childs
      for (let i = 0; i < this.selectedCookieType.cookies.length; i++) {
        this.selectedCookieType.cookies[i].is_cookie_enabled = false || this.selectedCookieType.cookies[i].is_neccessary;
      }
    }
  }

  changeCookieEnabled(cookie: Cookie) {
    // change cookie enabled
    cookie.is_cookie_enabled = !cookie.is_cookie_enabled;

    // activate cookietype if its false and cookie is enabled
    if (cookie.is_cookie_enabled && this.selectedCookieType.is_enabled == false) {
      this.selectedCookieType.is_enabled = true;
    }
  }

  acceptCookies() {
    // TODO: play hide-banner animation?
    this.accepted_cookies.emit(this.loaded_cookies);
    console.log("closing banner because accepted!");
    this.isCookieSettingsOpen = false;
  }
}
