import { Component, OnInit, HostListener, EventEmitter, Output, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'bottombanner',
  templateUrl: './bottombanner.component.html',
  styleUrls: ['./bottombanner.component.css']
})
export class BottombannerComponent implements OnInit {
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  @Output() openSettingsEmitter = new EventEmitter<boolean>();
  @Input() openSettingsInput: boolean;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  sendOpenCookieSettings() {
    this.openSettingsEmitter.emit(true);
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
