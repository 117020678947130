import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpModule } from '@angular/http';

/* Components */
import { TopbarComponent } from './topbar/topbar.component';
import { CookiebannerComponent } from './cookiebanner/cookiebanner.component';
import { BottombannerComponent } from './bottombanner/bottombanner.component';
import { TopbarDesktopComponent } from './topbar/topbar-desktop/topbar-desktop.component';
import { TopbarMobileComponent } from './topbar/topbar-mobile/topbar-mobile.component';
import { CookiebannerDesktopComponent } from './cookiebanner/cookiebanner-desktop/cookiebanner-desktop.component';
import { CookiebannerMobileComponent } from './cookiebanner/cookiebanner-mobile/cookiebanner-mobile.component';
import { BottombannerMobileComponent } from './bottombanner/bottombanner-mobile/bottombanner-mobile.component';
import { BottombannerDesktopComponent } from './bottombanner/bottombanner-desktop/bottombanner-desktop.component';

/* Services */
import { MouseService } from "@services/mouse.service";
import { ScrollService } from "@services/scroll.service";
import { GoogleAnalyticsService } from '@services/google-analytics.service.service';
import { CookieService } from '@services/cookie-service.service';

/* Own Modules */
import { SharedModule } from './../shared/shared.module';
import { MongdbService } from '@services/mongdb.service';
import { RouterModule } from '@angular/router';


@NgModule({
    imports: [CommonModule, SharedModule, MatButtonModule, MatButtonToggleModule, MatSlideToggleModule, HttpModule, RouterModule],
    providers: [MouseService, ScrollService, GoogleAnalyticsService, CookieService, MongdbService],
    declarations: [TopbarComponent, CookiebannerComponent, BottombannerComponent, TopbarDesktopComponent, TopbarMobileComponent, CookiebannerDesktopComponent, CookiebannerMobileComponent, BottombannerDesktopComponent, BottombannerMobileComponent],
    exports: [TopbarComponent, CookiebannerComponent, BottombannerComponent, TopbarDesktopComponent, TopbarMobileComponent, CookiebannerDesktopComponent, CookiebannerMobileComponent, BottombannerDesktopComponent, BottombannerMobileComponent],
    bootstrap: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error("CoreModule already exist, can't be created twice!");
        }
    }
}