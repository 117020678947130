import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home-module/home/home.component';


const routes: Routes = [
  { path: 'vorbereitung', loadChildren: () => import('./modules/tipps-module/tipps.module').then(m => m.TippsModule)},
  { path: 'learn-more', loadChildren: () => import('./modules/privacy-module/privacy.module').then(m => m.PrivacyModule)},
  { path: 'impressum', loadChildren: () => import('./modules/impressum-module/impressum.module').then(m => m.ImpressumModule)},
  { path: 'universitaeten', loadChildren: () => import('./modules/uni-module/uni.module').then(m => m.UniModule)},
  { path: 'infos', loadChildren: () => import('./modules/infos-module/infos.module').then(m => m.InfosModule)},
  { path: 'test', loadChildren: () => import('./modules/test-module/test.module').then(m => m.TestModule)},
  // old routes which should be removed later
  { path: 'wien', redirectTo: '/universitaeten/wien', pathMatch: 'full'},
  { path: 'salzburg', redirectTo: '/universitaeten/salzburg', pathMatch: 'full'},
  { path: 'graz', redirectTo: '/universitaeten/graz', pathMatch: 'full'},
  { path: 'innsbruck', redirectTo: '/universitaeten/innsbruck', pathMatch: 'full'},

  { path: 'wichtigste-infos', redirectTo: '/infos/wichtigste-infos', pathMatch: 'full'},
  { path: 'aufbau-inhalte', redirectTo: '/infos/aufbau-inhalte', pathMatch: 'full'},
  { path: 'anmeldung-test', redirectTo: '/infos/anmeldung-test', pathMatch: 'full'},

  { path: 'anleitung-vorbereitung', redirectTo: '/vorbereitung/anleitung-vorbereitung', pathMatch: 'full'},
  { path: 'buch-empfehlungen', redirectTo: '/vorbereitung/buch-empfehlungen', pathMatch: 'full'},
  { path: 'erfahrungen-studierende', redirectTo: '/vorbereitung/erfahrungen-studierende', pathMatch: 'full'},

  { path: 'home', component: HomeComponent},
  { path: '' , redirectTo: '/home', pathMatch: 'full'},
  { path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
