import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { CookieService } from './cookie-service.service';
import { Subscription } from 'rxjs';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  private gaRoutingSub: Subscription = new Subscription();

  constructor(private router: Router, private cookieService: CookieService) {
  }

  public event(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  public removeTracker() {
    console.log("Set Google Analytics Cookie");
    this.cookieService.setWithExpiryInYears("ga-opt-out", "true", 10);

    // unsubscribe routing events for ga
    this.gaRoutingSub.unsubscribe();
  }

  public init() {
    console.log("Set Google Analytics Cookie");

    this.listenForRouteChanges();

    try {

      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` + environment.googleAnalyticsKey + `', {'send_page_view': false});
      `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error('Error appending google analytics');
      console.error(ex);
    }
  }

  private listenForRouteChanges() {
    this.gaRoutingSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.googleAnalyticsKey, {
          'page_path': event.urlAfterRedirects,
        });
        console.log('Sending Google Analytics hit for route', event.urlAfterRedirects);
        console.log('Property ID', environment.googleAnalyticsKey);
      }
    });
  }
}
