import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'articlecontentbox',
  templateUrl: './articlecontentbox.component.html',
  styleUrls: ['./articlecontentbox.component.css']
})
export class ArticlecontentboxComponent implements OnInit {
  hidden: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  foldOut(): void {
    this.hidden = !this.hidden;
  }
}
