import { Directive, ElementRef, OnInit, OnDestroy, Renderer2, Input } from '@angular/core';
import { MouseService } from '../../core/services/mouse.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[mouseMove]'
})
export class MouseMoveDirective implements OnInit, OnDestroy {
  private mouseSub: Subscription = new Subscription();
  private img1: any;
  private img2: any;

  @Input() imgName1: string; 
  @Input() imgName2: string; 
  @Input() distTrigger: number = 10;
  @Input() top: number = 25;
  @Input() width: number = 50;

  constructor(private element: ElementRef, private mouse_service: MouseService,  private renderer: Renderer2) {
  }

  ngOnInit(): void {
    // subscribe to mouse pos
    this.mouseSub = this.mouse_service.mouseObs.subscribe(() => this.manageMouseMoves());

    // create Images outside viewport and make them ready to flyin by mouse movement
    this.img1 = this.renderer.createElement("img");
    this.img2 = this.renderer.createElement("img");
    this.renderer.setAttribute(this.img1, "src", this.imgName1);
    this.renderer.setAttribute(this.img2, "src", this.imgName2);
    this.renderer.setStyle(this.img1, "position", "absolute");
    this.renderer.setStyle(this.img2, "position", "absolute");
    this.renderer.setStyle(this.img1, "top", this.top);
    this.renderer.setStyle(this.img2, "top", this.top);
    this.renderer.setStyle(this.img1, "left", "-100%");
    this.renderer.setStyle(this.img2, "right", "-100%");
    this.renderer.setStyle(this.img1, "width", this.width);
    this.renderer.setStyle(this.img2, "width", this.width);
    this.renderer.appendChild(this.renderer.parentNode(this.element.nativeElement), this.img1);
    this.renderer.appendChild(this.renderer.parentNode(this.element.nativeElement), this.img2);
  }

  ngOnDestroy(): void {
    // unsubscribe from mouse pos
    this.mouseSub.unsubscribe();
  }

  private manageMouseMoves(): void {
    // calculate the distance from mouse to element
    const dist_vec_y = Math.abs((this.element.nativeElement.getBoundingClientRect().top + this.element.nativeElement.getBoundingClientRect().height/2) 
                    - (this.mouse_service.mouseY));
    const dist_vec_x = Math.abs((this.element.nativeElement.getBoundingClientRect().left + this.element.nativeElement.getBoundingClientRect().width/2) 
                    - (this.mouse_service.mouseX));                         
    let dist = Math.sqrt(dist_vec_x + dist_vec_y);
    if (dist <= this.distTrigger) {
      dist = this.distTrigger;
    }
    const max_dist = Math.sqrt((this.element.nativeElement.getBoundingClientRect().top + this.element.nativeElement.getBoundingClientRect().height/2) 
                      + (this.element.nativeElement.getBoundingClientRect().left + this.element.nativeElement.getBoundingClientRect().width/2));
    const dist_diff = dist / max_dist;
    this.renderer.setStyle(this.img1, "left", "-" + String(dist_diff*50) + "%");
    this.renderer.setStyle(this.img2, "right", "-" + String(dist_diff*50) + "%");                  
  }
}   
