import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'articlesuggestbox-desktop',
  templateUrl: './articlesuggestbox-desktop.component.html',
  styleUrls: ['./articlesuggestbox-desktop.component.css']
})
export class ArticlesuggestboxDesktopComponent implements OnInit {

  @Input('title') title: String;
  @Input('img_name') img_name: String;
  @Input('description') description: String;
  @Input('article_link') article_link: String;

  constructor() { }

  ngOnInit() {
  }

}
