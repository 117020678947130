import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'bottombanner-mobile',
  templateUrl: './bottombanner-mobile.component.html',
  styleUrls: ['./bottombanner-mobile.component.css']
})
export class BottombannerMobileComponent implements OnInit {

  @Output() openCookieSettingsEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  openCookieSettings() {
    this.openCookieSettingsEmitter.emit(true);
  }

}
