import { Component, OnInit, Input } from '@angular/core';
import { FAQ } from '@data/classes';

@Component({
  selector: 'faqbox-desktop',
  templateUrl: './faqbox-desktop.component.html',
  styleUrls: ['./faqbox-desktop.component.css']
})
export class FaqboxDesktopComponent implements OnInit {
  @Input('faqs') faqs: FAQ[];

  constructor() { }

  ngOnInit() {
  }

}
