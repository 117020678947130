import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'scolluparrow',
  templateUrl: './scolluparrow.component.html',
  styleUrls: ['./scolluparrow.component.css']
})
export class ScolluparrowComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  scrollUp(): void {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, 0); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
  }, 0);
  }
}
