import { FAQ } from './classes';

export const faqs_home: FAQ[] = [
    {question: "Wie ist das Verfahren für den Psychologie-Aufnahmetest gestaltet?", answer: "Es gibt einen schriftlichen multiple-choice Test mit drei inhaltlichen Teilen: Fachwissen-Aneignung, Methodik-Verständnis und englisches Textverständnis."},
    {question: "Wie erfolgt die Auswahl beim Aufnahmetest Psychologie?", answer: "Die Auswahl erfolgt nach einem Ranking: Die besten Ergebnisse erhalten einen Studienplatz."},
    {question: "Wann werden die Ergebnisse für den Test bekanntgegeben?", answer: "Das ist von Studienort zu Studienort unterschiedlich. In der Regel dauert die Auswertung der Testergebnisse zwei bis drei Wochen.  "},
    {question: "Wie viele Studienplätze gibt es?", answer: "Das ist vom Standort abhängig. Die meisten Studienplätze gibt es an der Universität Wien mit 500 Plätzen. "},
    {question: "An welchen Universitäten in Österreich wird der Aufnahmetest angeboten?", answer: "Der Aufnahmetest wird in den Städten Wien, Innsbruck, Salzburg, Graz und Klagenfurt angeboten. Klagenfurt hat jedoch einen gesonderten Test. "},
    {question: "Wann und wie oft findet der Aufnahmetest statt? ", answer: "Der Aufnahmetest findet jährlich Ende August an allen Studienorten gleichzeitig statt. Die genauen Termine werden meist in Frühjahr bekannt gegeben."},
    {question: "Wie oft kann ich zum Aufnahmetest in Psychologie antreten?", answer: "Solltest Du den Aufnahmetest nicht bestehen, kannst Du jedes Jahr erneut antreten. Du kannst Dich im darauffolgenden Jahr auch für einen anderen Studienort entscheiden."},
    {question: "Welche Voraussetzungen muss ich für den Aufnahmetest erfüllen?", answer: "Um Dich für ein Psychologie-Studium bewerben zu können, brauchst Du die allgemeine Universitätsreife. Außerdem solltest Du die prüfungsrelevanten Themen vorbereiten und Dich rechtzeitig bei Deinem Wunschstudienort registrieren."}
];