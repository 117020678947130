import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'articlesuggestbox',
  templateUrl: './articlesuggestbox.component.html',
  styleUrls: ['./articlesuggestbox.component.css']
})
export class ArticlesuggestboxComponent implements OnInit {

  @Input('title') title: String;
  @Input('img_name') img_name: String;
  @Input('description') description: String;
  @Input('article_link') article_link: String;

  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }

}
