import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'topbar-desktop',
  templateUrl: './topbar-desktop.component.html',
  styleUrls: ['./topbar-desktop.component.css']
})
export class TopbarDesktopComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }
}
