import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FAQ } from '@data/classes';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'dropdownbox',
  templateUrl: './dropdownbox.component.html',
  styleUrls: ['./dropdownbox.component.css']
})
export class DropdownboxComponent implements OnInit {
  @Input('faq') faq: FAQ;

  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
