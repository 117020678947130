import { Component, OnInit, Input } from '@angular/core';
import { MongdbService } from '@services/mongdb.service';
import { ActivatedRoute } from '@angular/router';
import { DataMsg, Msg, PostMsg } from '@data/classes';

@Component({
  selector: 'commentsection-mobile',
  templateUrl: './commentsection-mobile.component.html',
  styleUrls: ['./commentsection-mobile.component.css']
})
export class CommentsectionMobileComponent implements OnInit {
  msgdata: Msg[];
  msgs: DataMsg[];
  msganswer: boolean[];
  @Input('articletitle') articletitle: string;
  numbermsgs: number = 0;

  constructor(private mongoDBService: MongdbService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.mongoDBService.getMsgs(this.route.snapshot.url[0].path).subscribe(retmsgs => {
      this.msgs = new Array();
      this.msgdata = retmsgs;
      for (let i = 0; i < this.msgdata.length; i++) {
        this.msgs.push({"msg": this.msgdata[i], "rootmsg": true, "refmsgid": this.msgdata[i]._id});
        if (this.msgdata[i].answers.length > 0) {
          for (let y = 0; y < this.msgdata[i].answers.length; y++) {
            this.msgs.push({"msg": this.msgdata[i].answers[y], "rootmsg": false, "refmsgid": this.msgdata[i]._id});
          }
        }
      }
      this.numbermsgs = this.msgs.length;
      this.msganswer = Array.from({ length: this.msgs.length}, () => false);
    });
  }

  openmsginput(msg: any) {
    // clear ngif state array
    this.msganswer = Array.from({ length: this.msgs.length}, () => false);
    // get index of msg
    let index: number = this.msgs.indexOf(msg);
    // show commentinput box by setting ngif arrayelement to true
    this.msganswer[index] = true;
  }

  savenewmsg(msg: PostMsg) {
    // TODO perform some checks if msg should be pushed
    // get actual route
    msg.newmsg.site = this.route.snapshot.url[0].path;
    this.saveMsg(msg);  
  }

  saveMsg(msg: PostMsg) {
    this.mongoDBService.saveMsg(msg).subscribe(data => {
      this.ngOnInit();
    }, error => alert(error));
  }
}
