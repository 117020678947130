import { Component, OnInit, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'commentbox',
  templateUrl: './commentbox.component.html',
  styleUrls: ['./commentbox.component.css']
})
export class CommentboxComponent implements OnInit {
  @Input('msg') msg: any;
  @Output('answermsg') answermsg = new EventEmitter<any>();
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  openanswermsg(msg: any) {
    this.answermsg.emit(msg);
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
