import { Directive, Input, Renderer2, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ScrollService } from '../../core/services/scroll.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[animateOnScroll]'
})
export class AnimateOnScrollDirective implements OnInit, OnDestroy, AfterViewInit{

  private offsetTop: number;
  private isVisible: boolean;
  private winHeight: number;
  private elementHeight: number;
  private elementWidth: number;
  private isCentered: boolean;
  private parentNode: any;
  private element: any;
  private dummyElement: any;
  private coverTop: number;
  private scrollSub: Subscription = new Subscription();
  private resizeSub: Subscription = new Subscription();

  private get id(): string {
    return this.elementRef.nativeElement.id;
  }

  @Input() animationName: string; // use fadeIn as default if not specified
  // Pixel offset from screen bottom to the animated element to determine the start of the animation
  @Input() offset: number = 0;
  @Input() scrolllocked: boolean = false;
  @Input() centered: boolean = false;
  @Input() dummyHeight: number = 1.5;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private scroll: ScrollService) {
  }

  ngOnInit(): void {
    if (!this.animationName) {
      throw new Error('animationName required');
    }

    // get element
    this.element = this.elementRef.nativeElement;

    // default visibility to false
    this.isVisible = false;
    this.setVisibility(false);

    // default centered to false
    this.isCentered = false;

    // get parent node
    this.parentNode = this.renderer.parentNode(this.elementRef.nativeElement);

    if (this.parentNode == null) {
      throw new Error('there is no parent node available');
    }

    // subscribe to scroll event using service
    this.scrollSub = this.scroll.scrollObs
      .subscribe(() => this.manageVisibility());

    // subscribe to resize event using service so scrolling position is always accurate
    this.resizeSub = this.scroll.resizeObs
      .subscribe(() => this.manageVisibility());
  }

  ngAfterViewInit(): void {
    // run visibility check initially in case the element is already visible in viewport
    setTimeout(() => this.manageVisibility(), 1);
  }

  ngOnDestroy(): void {
    this.scrollSub.unsubscribe();
    this.resizeSub.unsubscribe();
  }

  /**
   * check for visibility of element in viewport to add animation
   *
   * @returns void
   */
  private manageVisibility(): void {
    // check for window height, may change with a window resize
    this.getWinHeight();

    // get vertical position for selected element
    this.getOffsetTop();

    // get element size
    this.getElementSize();

    // we should trigger the addition of the animation class a little after getting to the element
    const scrollTrigger = this.offsetTop + this.offset - this.winHeight;

    // create dummy element
    if (this.scrollLock && this.centered) {
      // this.createScrollContainer(this.element);
      this.createDummy();
    }

    // using values updated in service
    if (this.scroll.pos >= scrollTrigger) {
      if (this.scrolllocked) {
        this.scrollLock();
      }
      else {
        this.addAnimationClass();
      }
    }
    else {
      // set visiblily to hidden if not done yet
      if (this.isVisible) {
        this.setVisibility(false);
        this.isVisible = false;
      }
    }

  }

  /**
   * utility function to mark element visible and add css class
   *
   * @returns void
   */
  private addAnimationClass(): void {
    // mark this element visible, we won't remove the class after this
    this.isVisible = true;
    this.setVisibility(true);

    // use default for animate.css if no value provided
    this.setClass(this.animationName);
  }

  /**
   * scrolllock the element and add partial animation based on scrollposition
   * 
   * @returns void
   */
  private scrollLock(): void {
    let scroll_weight: number = (this.scroll.pos + this.winHeight) - this.offsetTop - this.offset;

    this.coverTop = (this.winHeight - this.elementHeight) / 2;

    if (scroll_weight <= this.elementHeight && scroll_weight >= 0 && (this.scroll.pos < (this.offsetTop + this.coverTop))) {
      let opacity_value: number = scroll_weight / (this.elementHeight); 

      // set new opacity value
      this.setOpacity(opacity_value);

      // center
      if (this.centered) {
        this.setCenter(true);
      }

      // set visibility if not done yet
      if (!this.isVisible) {
        this.setVisibility(true);
        this.isVisible = true;
        console.log("Setting visible by opacity: " + opacity_value);
      }
    }
    else if (scroll_weight > 1) {
      // set new opacity value
      this.setOpacity(1);

      // center
      if (this.centered) {
        this.setCenter(false);
      }

      // set visibility if not done yet
      if (!this.isVisible) {
        this.setVisibility(true);
        this.isVisible = true;
      }
    }
  }

  /**
   * Sets the opacity of the element
   * 
   * @param opacity 
   * @returns void
   */
  private setOpacity(opacity: number): void {
    if (opacity <= 1 && opacity >= 0) {
      this.renderer.setStyle(this.element, "opacity", opacity);
    }
    else {
      throw new Error('opacity out of range');
    }
  }

  /**
   * Centers the element in the viewport
   * 
   * @param center
   * @returns void
   */
  private setCenter(center: boolean): void {
    if (center === true && this.isCentered === false) {
      console.log("Set Center true");
      this.renderer.setStyle(this.element, "position", "fixed");
      this.renderer.setStyle(this.element, "top", "50%");
      this.renderer.setStyle(this.element, "left", "50%");
      this.renderer.setStyle(this.element, "transform", "translate(-50%, -50%)");
      this.isCentered = true;
    }
    else if(center === false && this.isCentered === true) {
      console.log("Set center false");
      this.renderer.setStyle(this.element, "position", "static");
      this.renderer.setStyle(this.element, "transform", "none");
      this.isCentered = false;
    }
  }

  private setVisibility(visibility: boolean): void {
    if (visibility) {
      this.renderer.setStyle(this.element, "visibility", "visible");
    }
    else {
      this.renderer.setStyle(this.element, "visibility", "hidden");
    }
  }

  /**
   * Sets the sticky style
   * 
   * @param sticky
   * @returns void
   */
  private setSticky(sticky: boolean): void {
    if (sticky === true) {
      this.renderer.setStyle(this.element, "position", "-webkit-sticky")
      this.renderer.setStyle(this.element, "position", "sticky");
    }
    else if (sticky === false) {
      this.renderer.setStyle(this.element, "position", "static");
    }
  }

  /**
   * utility function to add one or more css classes to element in DOM
   *
   * @param  {string} classes
   * @returns void
   */
  private setClass(classes: string): void {
    for (const c of classes.split(' ')) {
      this.renderer.addClass(this.element, c);
    }
  }

  /**
   * Creates a empty dummy element with height*dummyHeight and equal width
   * 
   * @param elementToClone 
   * @returns none
   */
  private createDummy(): any {
    if (this.dummyElement == null) {
      this.dummyElement = this.renderer.createElement("div");
      this.renderer.setStyle(this.dummyElement, "height", this.elementHeight*this.dummyHeight + "px");
      this.renderer.setStyle(this.dummyElement, "width", this.elementWidth + "px");
      this.renderer.setStyle(this.dummyElement, "display", "block");
      this.renderer.appendChild(this.parentNode, this.dummyElement);
    }
  }

  /**
   * Removes the created dummy element
   * 
   * @param none
   * @returns none
   */
  private removeDummy(): void {
    if (this.dummyElement != null) {
      this.renderer.removeStyle(this.dummyElement, "width");
      this.renderer.removeStyle(this.dummyElement, "height");
      this.renderer.removeChild(this.parentNode, this.dummyElement);
    }
    else {
      console.error("No dummy element to delete....");
    }
  }

  /**
   * get window height utility function
   *
   * @returns void
   */
  private getWinHeight(): void {
    this.winHeight = window.innerHeight;
  }

  /**
   * get element height
   * 
   * @returns void
   */
  private getElementSize(): void {
    this.elementHeight = (<HTMLElement>this.element).getBoundingClientRect().height;
    this.elementWidth = (<HTMLElement>this.element).getBoundingClientRect().width;
  }


  /**
   * get offsetTop value for element
   *  *
   * @returns void
   */
  private getOffsetTop(): void {
    // don't read values if centered
    if (this.isCentered === false) {
      const viewportTop = this.element.getBoundingClientRect().top;
      const clientTop = this.element.clientTop;

      // get vertical position for selected element
      this.offsetTop = viewportTop + this.scroll.pos - clientTop;
    }
  }

}
