import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { PostMsg, Msg, DataMsg } from '@data/classes';

@Component({
  selector: 'commentinput-desktop',
  templateUrl: './commentinput-desktop.component.html',
  styleUrls: ['./commentinput-desktop.component.css']
})
export class CommentinputDesktopComponent implements OnInit {
  @Output('newmsg') newmsg = new EventEmitter<PostMsg>();
  @Input('refmsg') refmsg: DataMsg;
  is_root_inputbox: boolean;

  msgForm = new FormGroup({
    sendername: new FormControl('', [
      Validators.required,
      Validators.minLength(3)
    ]),
    text: new FormControl('', [
      Validators.required,
      Validators.minLength(5)
    ])
  });

  constructor() { }

  ngOnInit() {
    // check for bottom inputbox
    if (this.refmsg == undefined) {
      this.is_root_inputbox = true;
    }
    else {
      this.is_root_inputbox = false;
    }
  }

  submitMsg() {
    // get actual date
    let date = new Date();
    // create new msg
    let msg: Msg = {_id: "", sendername: this.msgForm.get('sendername').value, postDate: date, text: this.msgForm.get('text').value, answers: [], site: ""};
    // send new msg
    if (this.refmsg == undefined) {
      this.newmsg.emit({newmsg: msg, refmsgid: null});
    }
    else {
      this.newmsg.emit({newmsg: msg, refmsgid: this.refmsg.refmsgid});
    }
    // clear forms
    this.resetForm(this.msgForm);
  }

  resetForm(form: FormGroup) {

    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
}
}
