import { Component, OnInit, Input, OnDestroy, ElementRef, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { Article } from 'src/app/data/classes';
import { Subscription, timer } from 'rxjs';
import { style, animate, AnimationMetadata, AnimationBuilder } from '@angular/animations';

@Component({
  selector: 'mainsuggestbox-desktop',
  templateUrl: './mainsuggestbox-desktop.component.html',
  styleUrls: ['./mainsuggestbox-desktop.component.css']
})
export class MainsuggestboxDesktopComponent implements OnInit, OnDestroy, AfterViewInit {
  public actual_article: Article;
  public article_index: number;
  private article_img: any;
  private suggest_box: any;
  private silbing: any;
  private subscription_timer: Subscription = new Subscription();
  private isClicked: boolean = false;
  @Input("slider_offset_ms") slider_offset: number;
  @Input("slider_timer_ms") slider_timer: number;
  @Input("articles") articles: Array<Article>;

  @ViewChild("article_img") initial_article_img: ElementRef;

  constructor(private builder: AnimationBuilder, private elementRef: ElementRef, private renderer: Renderer2) {

  }

  ngOnInit() {
    // init articles
    if (this.articles.length > 0) {
      this.actual_article = this.articles[0];
      this.article_index = 0;

      // set timer
      let time = timer(this.slider_offset, this.slider_timer);
      this.subscription_timer = time.subscribe(() => {
        this.nextArticleAutomatic();
      })
    }
    else {
      console.error("MainSuggestBox got an empty article array!");
    }
  }

  ngAfterViewInit() {
    // init view
    if (this.articles.length > 0) {
      this.article_img = this.initial_article_img.nativeElement;

      // get parent
      this.suggest_box = this.renderer.parentNode(this.article_img);

      // get silbing
      this.silbing = this.renderer.nextSibling(this.article_img);

      // set initial image source
      this.renderer.setAttribute(this.article_img, "src", ('/assets/images/' + this.actual_article.img_name));
    }
  }

  ngOnDestroy() {
    this.subscription_timer.unsubscribe();
  }

  nextArticleAutomatic(): void {
    // check if a clicked was made by user and reset timer
    if (this.isClicked) {
      // unsubscribe old timer
      this.subscription_timer.unsubscribe();
      // set new timer
      let time = timer(this.slider_offset, this.slider_timer);
      this.subscription_timer = time.subscribe(() => {
        this.nextArticleAutomatic();
      })

      this.isClicked = false;
    }
    else {
      if (this.articles.length - 1 > this.article_index) {
        this.article_index = this.article_index + 1;
        this.actual_article = this.articles[this.article_index];
      }
      else {
        this.article_index = 0;
        this.actual_article = this.articles[this.article_index];
      }
      this.playFlyInOut();
    }
  }

  nextArticle(): void {
    this.isClicked = true;
    if (this.articles.length - 1 > this.article_index) {
      this.article_index = this.article_index + 1;
      this.actual_article = this.articles[this.article_index];
    }
    else {
      this.article_index = 0;
      this.actual_article = this.articles[this.article_index];
    }
    this.playFlyInOut();
  }

  lastArticle(): void {
    this.isClicked = true;
    if (this.article_index > 0) {
      this.article_index = this.article_index - 1;
      this.actual_article = this.articles[this.article_index];
    }
    else {
      this.article_index = this.articles.length - 1;
      this.actual_article = this.articles[this.article_index];
    }
    this.playFlyInOut();
  }

  private playFlyInOut() {
    // create new image
    let new_img: any = this.renderer.createElement("img");
    this.renderer.addClass(new_img, "img-background");
    this.renderer.setAttribute(new_img, "src", ('/assets/images/' + this.actual_article.img_name));
    // this.renderer.setStyle(new_img, "height", "80vh");
    // this.renderer.setStyle(new_img, "position", "absolute");
    // this.renderer.setStyle(new_img, "width", "100vh");
    this.renderer.setStyle(new_img, "left", "-100%");
    this.renderer.insertBefore(this.suggest_box, new_img, this.article_img);
    
    const metadata = this.flyIn();
    const factory1 = this.builder.build(metadata);
    const factory2 = this.builder.build(metadata);
    const player1 = factory1.create(this.article_img);
    const player2 = factory2.create(new_img);

    player1.play();
    player2.play();

    // remove old element
    this.renderer.removeChild(this.suggest_box, this.article_img);
    this.article_img = new_img;
  }

  private flyIn(): AnimationMetadata[] {
    return [
      style({ transform: '*' }),
      animate('400ms ease-in-out', style({ transform: 'translateX(100%)' }))
    ];
  }

  private flyOut(): AnimationMetadata[] {
    return [
      style({ top: 0 }),
      style({ transform: '*' }),
      animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' }))
    ];
  }
}
