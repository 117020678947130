import { Component, OnInit, HostListener, Output, EventEmitter, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { PostMsg, DataMsg } from '@data/classes';

@Component({
  selector: 'commentinput',
  templateUrl: './commentinput.component.html',
  styleUrls: ['./commentinput.component.css']
})
export class CommentinputComponent implements OnInit {
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;
  @Output("newmsg") newmsg = new EventEmitter<PostMsg>();
  @Input('refmsg') refmsg: DataMsg;

  constructor(private deviceService: DeviceDetectorService) {
    this.checkDevice();
    this.checkSize();
  }

  ngOnInit() {
  }

  savenewmsg(msg: PostMsg) {
    this.newmsg.emit(msg);
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
