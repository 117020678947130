import { Component, OnInit } from '@angular/core';
import { Article, FAQ } from 'src/app/data/classes';
import {suggestArticles} from 'src/app/data/suggestArticles';
import { faqs_home } from '@data/faq';

@Component({
  selector: 'home-mobile',
  templateUrl: './home-mobile.component.html',
  styleUrls: ['./home-mobile.component.css']
})
export class HomeMobileComponent implements OnInit {
  public articles: Array<Article>;
  public faqs: FAQ[];

  constructor() {
    this.articles = suggestArticles;
    this.faqs = faqs_home;
  }

  ngOnInit() {
  }
}
