import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'articlesuggestbox-mobile',
  templateUrl: './articlesuggestbox-mobile.component.html',
  styleUrls: ['./articlesuggestbox-mobile.component.css']
})
export class ArticlesuggestboxMobileComponent implements OnInit {

  @Input('title') title: String;
  @Input('img_name') img_name: String;
  @Input('description') description: String;
  @Input('article_link') article_link: String;

  constructor() { }

  ngOnInit() {
  }

}
