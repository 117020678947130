import { Directive, Input, ElementRef, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { ScrollService } from '../../core/services/scroll.service';
import { AnimationBuilder } from '@angular/animations';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[stickycontents]'
})
export class StickycontentsDirective implements OnDestroy, OnInit {
  // TODO: check whats happening by resizing in the session -> will the contentbox resize on the fly?
  // TODO: check end of article, so that contentbox doesn't crash into articlesuggestboxes or bottombar
  // TODO: By Resize, change size of dummyelement too 

  private scrollSub: Subscription = new Subscription();
  private resizeSub: Subscription = new Subscription();
  private isMinimized: boolean = false;
  private contentsBoxTop: number;
  private endOfArticle: number;
  private isVisible: boolean = true;
  private dummyelement: any;
  private nativeParentNode: any;

  @Input() fontSmallEm: number = 0.8;
  @Input() fontBigEm: number = 1;
  @Input() sizeMinimizedVW: number = 25;
  @Input() sizeMaximizedVW: number = 50;
  @Input() minimizeOffset: number = 5;
  @Input() hidingOffset: number = -20;
  @Input() normalListClass: string = "normal-li";
  @Input() smallListClass: string = "small-li";

  constructor(private scroll: ScrollService, private elementRef: ElementRef, private renderer: Renderer2, private builder: AnimationBuilder) {

  }

  ngOnInit(): void {
    // subscribe to scroll event using service
    this.scrollSub = this.scroll.scrollObs
      .subscribe(() => this.checkScrolling());

    // subscribe to resize event using service so scrolling position is always accurate
    this.resizeSub = this.scroll.resizeObs
      .subscribe(() => this.checkScrolling());

    // get parent node
    this.nativeParentNode = this.renderer.parentNode(this.elementRef.nativeElement);

    // get top position of contentsbox, (because top value changes by scrolling)
    this.contentsBoxTop = this.elementRef.nativeElement.getBoundingClientRect().top;
 
    // get the end of the article
    this.endOfArticle = this.nativeParentNode.getBoundingClientRect().top + this.nativeParentNode.getBoundingClientRect().height;

    // create clone of contentsbox
    this.dummyelement = this.renderer.createElement('div');
    this.renderer.setStyle(this.dummyelement, 'min-height', String(this.elementRef.nativeElement.getBoundingClientRect().height) + 'px');
    this.renderer.setStyle(this.dummyelement, 'width', String(this.elementRef.nativeElement.getBoundingClientRect().width)  + 'px');
    this.renderer.setStyle(this.dummyelement, 'display', 'block');
  }

  ngOnDestroy(): void {
    this.scrollSub.unsubscribe();
    this.resizeSub.unsubscribe();
  }

  private checkScrolling(): void {
    console.log("ScrollPos: " + this.scroll.pos);
    // check if user scrolled that far, so contents-box isn't visible anymore
    if (this.isMinimized === false && this.scroll.pos > this.elementRef.nativeElement.getBoundingClientRect().height + this.minimizeOffset + this.contentsBoxTop) {
      // minimize the contentbox
      this.minimizeContents();
    }
    else if (this.isMinimized === true && this.scroll.pos <= this.elementRef.nativeElement.getBoundingClientRect().height + this.minimizeOffset + this.contentsBoxTop) {
      this.maximizeContents();
    }

    // check if end of article is reached, and hide contentsbox
    if ((this.scroll.pos > (this.endOfArticle - this.elementRef.nativeElement.getBoundingClientRect().height + this.hidingOffset)) && this.isVisible === true) {
      this.setVisibility(false);
    }
    else if ((this.scroll.pos <= (this.endOfArticle - this.elementRef.nativeElement.getBoundingClientRect().height + this.hidingOffset)) && this.isVisible === false) {
      this.setVisibility(true);
    }
  }

  private minimizeContents(): void {
    if (this.isMinimized === false) {
      // minimize the contentsbox
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', '80px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'right', '16px');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', String(this.fontSmallEm) + "em");
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', String(this.sizeMinimizedVW) + 'vw');

      // TODO: make small text displayed
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', String(this.sizeMinimizedVW) + 'vw');

      // add dummyelement
      this.renderer.insertBefore(this.nativeParentNode, this.dummyelement, this.elementRef.nativeElement);

      // set contentsbox as minimized
      this.isMinimized = true;
    }
  }

  private maximizeContents(): void {
    if (this.isMinimized === true) {
      // maximize the contentsbox
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'static');
      this.renderer.setStyle(this.elementRef.nativeElement, 'font-size', String(this.fontBigEm) + "em");
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', String(this.sizeMaximizedVW) + 'vw');

      // TODO: make 

      // remove dummyelement
      this.renderer.removeChild(this.nativeParentNode, this.dummyelement);

      // set contentsbox as maximized
      this.isMinimized = false;
    }
  }

  private setVisibility(visible: boolean): void {
    if (visible === true && this.isVisible === false) {
      this.renderer.setStyle(this.elementRef.nativeElement, "visibility", "visible");
      this.isVisible = true;
    }
    else if (visible === false && this.isVisible === true) {
      this.renderer.setStyle(this.elementRef.nativeElement, "visibility", "hidden");
      this.isVisible = false;
    }
  }
}
