import { Component, ElementRef, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from './core/services/cookie-service.service';
import { GoogleAnalyticsService } from './core/services/google-analytics.service.service';
import { CookieType } from './data/classes';
import { cookies } from './data/cookies';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showCookieBanner: boolean = false;
  showCookieSettings: boolean = false;
  private cookieLawSeen: boolean;
  private accepted_cookie: boolean = false;
  cookie_data: CookieType[];


  constructor(private titleService: Title, private router: Router, private cookieservice: CookieService, private googleAnalytics: GoogleAnalyticsService,
    private element: ElementRef, private renderer: Renderer2) {
    // load cookie data from file
    this.cookie_data = cookies;

    // TODO remove this after routing fixed
    /*
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url != undefined && event.url === "/") {
            this.router.navigate(['home']);
          }
        }
      });
      */
  }

  ngOnInit(): void {
    // set title
    // TODO: change title
    this.titleService.setTitle("Aufnahmetest Psychologiestudium Österreich");

    // check if cookie was already set or declined and show cookiebanner if not
    if (this.cookieservice.checkIfCookieExists() === false) {
      // show cookiebanner
      this.showCookieBanner = true;
    }
  }

  openCookieSettings() {
    this.showCookieSettings = true;
  }

  acceptedCookie(accepted_cookies: CookieType[]): void {
    // check the cookies
    for (let i = 0; i < accepted_cookies.length; i++) {
      for (let y = 0; y < accepted_cookies[i].cookies.length; y++) {
        // check for Google Analytics
        if (accepted_cookies[i].cookies[y].cookie_name == "Google Analytics Cookie") {
          if (accepted_cookies[i].cookies[y].is_cookie_enabled) {
            this.setGoogleAnalyticsCookie();
            this.accepted_cookie = true;
          } else {
            this.removeGoogleAnalytics();
            this.accepted_cookie = false;
          }
        }
      }
    }

    // hide cookiebanner
    this.showCookieBanner = false;
    this.showCookieSettings = false;

    // set cookies as accepted or declined
    if (this.acceptedCookie) {
      this.cookieservice.setCookieAccepted(true);
    }
    else {
      this.cookieservice.setCookieAccepted(false);
    }
  }

  setGoogleAnalyticsCookie() {
    this.googleAnalytics.init();
  }

  removeGoogleAnalytics() {
    this.googleAnalytics.removeTracker();
  }
}
