import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { HomeDesktopComponent } from './home/home-desktop/home-desktop.component';
import { HomeMobileComponent } from './home/home-mobile/home-mobile.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [HomeComponent, HomeDesktopComponent, HomeMobileComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: []
})
export class HomeModule { }
