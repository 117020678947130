import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';

@Injectable({
    providedIn: 'root'
  })
export class MouseService implements OnDestroy {

    mouseObs: Observable<any>;
    mouseX: number = 0;
    mouseY: number = 0;
    private mouseSub: Subscription = new Subscription();

    constructor() {
        // create observable that we can subscribe to from component or directive
        this.mouseObs = fromEvent(window, 'mousemove');

        // initiate subscription to update values
        this.mouseSub = this.mouseObs
        .subscribe((evt: MouseEvent) => this.manageMousePos(evt));
    }

    private manageMousePos(evt: MouseEvent): void {
        // update service property
        this.mouseX = evt.clientX;
        this.mouseY = evt.clientY;
    }

    ngOnDestroy(): void {
        this.mouseSub.unsubscribe();
    }

}