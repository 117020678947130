import { Component, OnInit, HostListener } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser'; 
import { siteDescriptions } from "@data/descriptions";
import { SiteDescription } from '@data/classes';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public isMobile: boolean = false;
  public isDesktopDevice: boolean = false;
  public isTablet: boolean = false;
  public isSmallWindow: boolean = false;
  public description: SiteDescription;

  constructor(private deviceService: DeviceDetectorService, private titleService: Title, private meta: Meta) {
    this.checkDevice();
    this.checkSize();
    this.description = siteDescriptions[0];
  }

  ngOnInit() {
    this.titleService.setTitle(this.description.title);
    this.meta.addTags([
      {name: 'description', content: this.description.description},   
      {name: 'viewport', content: this.description.viewport},   
      {name: 'robots', content: this.description.robots},
      {name: 'keywords', content: this.description.keywords},
      {name: 'date', content: this.description.date, scheme: 'YYYY-MM-DD'},
      {httpEquiv: 'Content-Type', content: this.description.contentType},
      {property: 'og:title', content: this.description.title},
      {property: 'og:type', content: this.description.type},
      {charset: this.description.charset}
   ]);   
  }

  checkDevice() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  checkSize() {
    if (window.innerWidth <= 768) {
      this.isSmallWindow = true;
    }
    else {
      this.isSmallWindow = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkSize();
  }
}
