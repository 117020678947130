import { Directive, Input, ElementRef, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { ScrollService } from '../../core/services/scroll.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[Scrollup]'
})
export class ScrollupDirective implements OnInit, OnDestroy{
  private nativeParentNode: any;
  private visible: boolean = false;
  private scrollSub: Subscription = new Subscription();
  private resizeSub: Subscription = new Subscription();
  

  constructor(private scroll: ScrollService, private elementRef: ElementRef, private renderer: Renderer2) {

  }

  ngOnInit(): void {
    // subscribe to scroll event using service
    this.scrollSub = this.scroll.scrollObs
      .subscribe(() => this.checkScrolling());

    // subscribe to resize event using service so scrolling position is always accurate
    this.resizeSub = this.scroll.resizeObs
      .subscribe(() => this.checkScrolling());

      // get parent node
    this.nativeParentNode = this.renderer.parentNode(this.elementRef.nativeElement);
  }

  checkScrolling(): void {
    // check if use has scrolled down
    if (this.scroll.pos > 0 && this.visible == false) {
      this.setVisibility(true);
      this.visible = true;
    }

    if (this.scroll.pos == 0 && this.visible) {
      this.setVisibility(false);
      this.visible = false;
    }
  }

  ngOnDestroy(): void {
    this.scrollSub.unsubscribe();
    this.resizeSub.unsubscribe();
  }

  setVisibility(visible: boolean): void {
    if (visible) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
    }
    else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'none');
    }
  }
}
