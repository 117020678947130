import { Component, OnInit, Input } from '@angular/core';
import { Article } from 'src/app/data/classes';

@Component({
  selector: 'mainsuggestbox-mobile',
  templateUrl: './mainsuggestbox-mobile.component.html',
  styleUrls: ['./mainsuggestbox-mobile.component.css']
})
export class MainsuggestboxMobileComponent implements OnInit {
  public actual_article: Article;

  @Input("slider_offset_ms") slider_offset: number;
  @Input("slider_timer_ms") slider_timer: number;
  @Input("articles") articles: Array<Article>;

  constructor() { }

  ngOnInit() {
    if (this.articles.length > 0) {
      this.actual_article = this.articles[0];
    }
    else {
      console.error("MainSuggestBox got an empty article array!");
    }
  }

}
