import { SiteDescription } from '@data/classes';

export const siteDescriptions: SiteDescription[] = [
/* (0) home */    {title: "Aufnahmetest Psychologiestudium Österreich", description: "Den Aufnahmetest für das Psychologiestudium in Österreich bestehst du mit unseren Informationen garantiert.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmtest, Psychologiestudium, Österreich", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (1) infos */    {title: "Überblick Infos Aufnahmetest Psychologie", description: "Hier erhältst du einen Überblick über alles, was wir dir für Informationen zum Aufnahmetest Psychologie anbieten.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Übersicht, Infos, Informationen", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (2) aufbau und Inhalte */    {title: "Aufbau und Inhalte des Aufnahmetest Psychologie", description: "Den Aufbau und die Inhalte des Aufnahmetests für das Psychologiestudium haben wir für dich zusammengefasst.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmtest, Psychologie, Aufbau, Inhalte, Psychologiestudium", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (3) wichtigste infos */    {title: "Wichtigste Infos Aufnahmetest Psychologie", description: "Hier erhältst du alle wichtigen Informationen zum Aufnahmetest für Psychologie.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, wichtigste, Infos, Informationen", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (4) anmeldung-test */    {title: "Anmeldung zum Aufnahmetest Psychologie", description: "Alles was du wissen musst, um dich zum Aufnahmetest für das Psychologiestudium anzumelden.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmtest, Psychologie, Anmeldung, Psychologiestudium", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (5) Tippsvorbereitung */    {title: "Überblick Tipps zur Vorbereitung Aufnahmetest Psychologie", description: "Mit diesem Überblick über unsere Tipps zur Vorbereitung auf den Aufnahmetest Psychologie bist du besten gerüstet.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "'Aufnahmetest, Psychologie, Übersicht, Tipps, Vorbereitung", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (6) Erfahrungen */    {title: "Erfahrungen anderer Studierender für das Psychologiestudium", description: "Erfahre wie andere Studierende die Aufnahmeprüfung und das Psychologiestudium empfunden haben.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Erfahrungen, Erfahrungsberichte, Studenten, Studierende", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (7) Buch Empfehlungen */    {title: "Buch Empfehlungen für den Aufnahmetest Psychologie", description: "Wir haben eine Liste mit allen notwendigen Büchern für die Vorbereitung auf den Aufnahmetest Psychologie erstellt.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Buch, Bücher, Empfehlungen", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (8) Anleitung Vorbereitung */    {title: "Anleitung für den Aufnahmetest Psychologie", description: "Mit dieser Anleitung zur Vorbereitung auf den Aufnahmetest Psychologie bist den anderen voraus.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Anleitung, Vorbereitung", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (9) Unis & Staedte */    {title: "Überblick über Städte und Unis Aufnahmetest Psychologie", description: "Hier erhältst du einen Überblick über alle Städte und Universitäten. Wir zeigen dir die Unterschiede der einzelnen Aufnahmetests.", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Übersicht, Städte, Unis, Universitäten", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (10) Salzburg */    {title: "Aufnahmetest Psychologie in Salzburg", description: "Alle Informationen zum Psychologiestudium an der Universität in Salzburg", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Salzburg, Uni, Universität", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (11) Wien */    {title: "Aufnahmetest Psychologie in Wien", description: "Alles zum Psychologiestudium an der Universität in Wien", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Wien, Uni, Universität", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (12) Graz */ {title: "Aufnahmetest Psychologie in Graz", description: "Alles zum Psychologiestudium an der Universität in Graz", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Graz, Uni, Universität", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (13) Innsbruck */ {title: "Aufnahmetest Psychologie in Innsbruck", description: "Alles zum Psychologiestudium an der Universität in Innsbruck", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Innsbruck, Uni, Universität", date: "2019-08-26", contentType: "text/html", type: "website", charset: "UTF-8"},
/* (14) */ { title: "Aufnahmetest Psychologie Simulation ", description: "Teste dich für die anstehende Aufnahmeprüfung mit unserer Simulation", viewport: "width=device-width, initial-scale=1", robots: "INDEX, FOLLOW", keywords: "Aufnahmetest, Psychologie, Simulation, Uni, Universität", date: "2021-05-09", contentType: "text/html", type: "website", charset: "UTF-8"},
]