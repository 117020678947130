import { Directive, Input, OnDestroy, OnInit, ElementRef, Renderer2, ContentChildren, QueryList, ViewChild, ContentChild, ViewChildren } from '@angular/core';
import { ScrollService } from '../../core/services/scroll.service';
import { animate, style, AnimationBuilder, AnimationMetadata } from '@angular/animations';
import { Subscription, Observable } from 'rxjs';
import { MouseService } from '../../core/services/mouse.service';

@Directive({
  selector: '[appTopbar]'
})
export class TopbarDirective implements OnDestroy, OnInit {
  /** Each dropdown-selector field must've got the id: #dropdownselectorfield */

  private scrollSub: Subscription = new Subscription();
  private resizeSub: Subscription = new Subscription();
  private mouseSub: Subscription = new Subscription();
  private isCollapsedSubscription: Subscription = new Subscription();
  private lastScrollPos: number = 0;
  private scrollhidden: boolean = false;
  private scrollLock: number = 0;
  private winHeight: number;
  private isVisible: boolean = true;
  private isFirstHide: boolean = true;
  private isScrollEventActive: boolean = false;
  private isCollapsed: boolean = false;

  @Input() hideOnScrollDown: boolean = false;
  @Input() scrollTrigger: number = 20;
  @Input() flyInOut: boolean = false;
  @Input() hotfixFlyInOut: boolean = false;
  @Input() visibleOnHover: boolean = false;
  @Input() isMobile: boolean = false;
  @Input() isCollapsed$: Observable<boolean>;

  @ContentChildren('dropdownselectorfield', {descendants: true}) selectorfields !: QueryList<ElementRef>;

  constructor(private scroll: ScrollService, private elementRef: ElementRef, private renderer: Renderer2, private builder: AnimationBuilder,
    private mouse_service: MouseService) {

  }

  ngOnInit() {
    // subscribe to scroll event using service
    this.scrollSub = this.scroll.scrollObs
      .subscribe(() => this.checkScrolling());

    // subscribe to resize event using service so scrolling position is always accurate
    this.resizeSub = this.scroll.resizeObs
      .subscribe(() => this.checkScrolling());

    if (this.visibleOnHover) {
      this.mouseSub = this.mouse_service.mouseObs.subscribe(() => this.checkMousePos());
    }

    // subscribe to nav-menu collapsed state
    if (this.isMobile) {
      this.isCollapsedSubscription = this.isCollapsed$.subscribe((isCollapsed_updated) => {
        this.isCollapsed = isCollapsed_updated;
      })
    }

    // get window height
    this.getWinHeight();
  }

  ngOnDestroy(): void {
    this.scrollSub.unsubscribe();
    this.resizeSub.unsubscribe();

    if (this.isMobile) {
      this.isCollapsedSubscription.unsubscribe();
    }

    if (this.visibleOnHover) {
      this.mouseSub.unsubscribe();
    }
  }

  private checkScrolling() {
    if (this.scrollhidden) {
      this.isScrollEventActive = true;
    }

    // Only check if scoll is over winheight
    if (this.scroll.pos > this.winHeight) {
      // check for scollup
      if (this.scroll.pos < this.lastScrollPos) {
        // reset values
        this.scrollLock = 0;
        if (!this.isVisible) {
          this.setTopbarVisibility(true);
          this.scrollhidden = false;
        }
      }
      else {
        // check if scrolllock is already set
        if (this.scrollLock === 0) {
          this.scrollLock = this.scroll.pos;
        }
        else {
          // check trigger
          if ((this.scroll.pos - this.scrollLock) > this.scrollTrigger) {
            // hide topbar
            if (this.isVisible) {
              this.setTopbarVisibility(false);
              this.scrollhidden = true;
            }
          }
        }
      }
    }
    else {
      // TODO: set visibility and the other values only if topbar isn't visible yet
      // set topbar as visible (by routing)
      this.scrollLock = 0;
      this.scrollhidden = false;
      this.setTopbarVisibility(true);
    }

    // update last scoll position
    this.lastScrollPos = this.scroll.pos;

    if (this.scrollhidden) {
      this.isScrollEventActive = false;
    }
  }


  /**
   * Check mouse position and make topbar visible if hovered
   */
  private checkMousePos(): void {
    if (!this.isScrollEventActive) {
      // only check if hidden by scrollbar
      if (!this.isVisible && this.scrollhidden) {
        // check mousepos and topbarpos (only need to check height cause topbar is always 100% width)
        if (this.mouse_service.mouseY <= this.elementRef.nativeElement.getBoundingClientRect().height) {
          this.setTopbarVisibility(true);
        }
      }
      if (this.isVisible && this.scrollhidden) {
        // check mousepos and topbarpos (only need to check height cause topbar is always 100% width)
        if (this.mouse_service.mouseY > this.elementRef.nativeElement.getBoundingClientRect().height) {
          // check if mouse is on any selectionfield
          let mouse_inner_selectorfield: Boolean = false;
          this.selectorfields.forEach(selectorfieldInstance => {
            let selectorfield_height: any = selectorfieldInstance.nativeElement.getBoundingClientRect().height;
            let selectorfield_offset_top: any = selectorfieldInstance.nativeElement.getBoundingClientRect().top;
            let selectorfield_width: any = selectorfieldInstance.nativeElement.getBoundingClientRect().width;
            let selectorfield_offset_left: any = selectorfieldInstance.nativeElement.getBoundingClientRect().left;
            if (this.mouse_service.mouseY <= (selectorfield_height + selectorfield_offset_top) && this.mouse_service.mouseY >= selectorfield_offset_top
              && this.mouse_service.mouseX <= (selectorfield_offset_left + selectorfield_width) && this.mouse_service.mouseX >= selectorfield_offset_left) {
              mouse_inner_selectorfield = true;
            }
          }); 
          if (!mouse_inner_selectorfield) {
            this.setTopbarVisibility(false);
          }
        }
      }
    }
  }

  /**
   * Sets visibility of the topbar
   * 
   * @param visible
   * @returns void
   */
  private setTopbarVisibility(visible: boolean): void {
    if (this.isMobile && !this.isCollapsed || !this.isMobile) {
      if (visible) {
        if (this.flyInOut) {
          this.playFlyInOut(true);
          this.isVisible = true;
        }
        else {
          this.renderer.setStyle(this.elementRef.nativeElement, "visibility", "visible");
          this.isVisible = true;
        }
      }
      else {
        if (this.flyInOut) {
          this.playFlyInOut(false);
          this.isVisible = false;
        }
        else {
          this.renderer.setStyle(this.elementRef.nativeElement, "visibility", "hidden");
          this.isVisible = false;
        }
      }
    }
  }

  /**
   * get window height utility function
   *
   * @returns void
   */
  private getWinHeight(): void {
    this.winHeight = window.innerHeight;
  }

  private playFlyInOut(flyIn: boolean) {
    const metadata = flyIn ? this.flyIn() : this.flyOut();
    const factory = this.builder.build(metadata);
    const player = factory.create(this.elementRef.nativeElement);

    player.play();
  }

  private flyIn(): AnimationMetadata[] {
    if (this.hotfixFlyInOut) {
      return [
        style({ transform: '*' }),
        animate('300ms ease-in-out', style({ transform: 'translateY(0.01%)' }))
      ];
    }
    return [
      style({ transform: '*' }),
      animate('300ms ease-in-out', style({ transform: 'translateY(100%)' }))
    ];
  }

  private flyOut(): AnimationMetadata[] {
    if (this.hotfixFlyInOut) {
      if (this.isFirstHide) {
        return [
          style({ top: 0 }),
          style({ transform: '*' }),
          animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' }))
        ];
      }
      else {
        return [
          style({ top: 0 }),
          style({ transform: '*' }),
          animate('300ms ease-in-out', style({ transform: 'translateY(-0.01%)' }))
        ];
      }
    }

    this.isFirstHide = false;

    return [
      style({ top: 0 }),
      style({ transform: '*' }),
      animate('300ms ease-in-out', style({ transform: 'translateY(-100%)' }))
    ];
  }
}
