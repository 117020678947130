import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'topbar-mobile',
  templateUrl: './topbar-mobile.component.html',
  styleUrls: ['./topbar-mobile.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('expandCollapseState', style({ height: '*' })),
      transition('* => void', [style({ height: '*' }), animate(250, style({ height: "0" }))]),
      transition('void => *', [style({ height: '0' }), animate(250, style({ height: "*" }))])
    ]),
    trigger('rotateIcon', [
      state('rotate', style({ transform: 'rotateX(-180deg) ' })),
      state('unRotate', style({ transform: 'rotateX(0deg) ' })),
      transition('* => *', [
        animate('200ms')
      ])
    ])
    ]
})

// state('rotateIconState', style({ transform: '*' })),
//transition('* => void', [style({ transform: '*' }), animate(250, style({ transform: 'rotateX(0deg) rotateZ(-90deg)' }))]),
//transition('void => *', [style({ transform: 'rotateX(0deg) rotateZ(-90deg)' }), animate(250, style({ transform: '*' }))])

export class TopbarMobileComponent implements OnInit {
  public isCollapsed: boolean = false;
  public isInfosCollapsed: boolean = false;
  public isVorbereitungCollapsed: boolean = false;
  public isUnisCollapsed: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {

  }

  toogleMenu() {
    this.isCollapsed = !this.isCollapsed;
    this.isUnisCollapsed = false;
    this.isInfosCollapsed = false;
    this.isVorbereitungCollapsed = false;
  }

  toogleInfos() {
    this.isUnisCollapsed = false;
    this.isInfosCollapsed = !this.isInfosCollapsed;
    this.isVorbereitungCollapsed = false;
  }

  toogleVorbereitung() {
    this.isUnisCollapsed = false;
    this.isInfosCollapsed = false;
    this.isVorbereitungCollapsed = !this.isVorbereitungCollapsed;
  }

  toogleUnis() {
    this.isUnisCollapsed = !this.isUnisCollapsed;
    this.isInfosCollapsed = false;
    this.isVorbereitungCollapsed = false;
  }

  routingArticle(routerLink: string) {
    this.isCollapsed = false;
    this.router.navigate([routerLink]);
  }
}
