import { Component, OnInit, Input } from '@angular/core';
import { FAQ } from '@data/classes';

@Component({
  selector: 'dropdownbox-desktop',
  templateUrl: './dropdownbox-desktop.component.html',
  styleUrls: ['./dropdownbox-desktop.component.css']
})
export class DropdownboxDesktopComponent implements OnInit {
  @Input('faq') faq: FAQ;

  constructor() { }

  ngOnInit() {
  }

}
