import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bottombanner-desktop',
  templateUrl: './bottombanner-desktop.component.html',
  styleUrls: ['./bottombanner-desktop.component.css']
})
export class BottombannerDesktopComponent implements OnInit {

  @Output() openCookieSettingsEmitter = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  openCookieSettings() {
    this.openCookieSettingsEmitter.emit(true);
  }

}
