export class Article {
    public title: string;
    public description: string;
    public img_name: string;
    public article_link: string;

    constructor (title: string, img_name: string, article_link: string, description: string) {
        this.title = title;
        this.img_name = img_name;
        this.article_link = article_link;
        this.description = description;
    }
}

export class SiteDescription {
    public title: string;
    public description: string;
    public viewport: string;
    public keywords: string;
    public date: string;
    public robots: string;
    public contentType: string;
    public type: string;
    public charset: string;

    constructor (title: string, description: string, viewport: string, keywords: string, date: string, robots: string, contentType: string, type: string, charset: string) {
        this.title = title;
        this.description = description;
        this.viewport = viewport;
        this.keywords = keywords; 
        this.date = date;
        this.robots = robots;
        this.contentType = contentType;
        this.type = type;
        this.charset = charset;
    }
}

export class Msg {
    public _id: string;
    public sendername: string;
    public text: string;
    public answers: Msg[];
    public postDate: Date;
    public site: string;

    constructor (_id: string, sendername: string, text: string, answers: Msg[], postDate: Date, site: string) {
        this._id = _id;
        this.sendername = sendername;
        this.text = text;
        this.answers = answers;
        this.postDate = postDate;
        this.site = site;
    }
}

export class PostMsg {
    public newmsg: Msg;
    public refmsgid: string;

    constructor (newmsg: Msg, refmsgid: string) {
        this.newmsg = newmsg;
        this.refmsgid = refmsgid;
    }
}

export class DataMsg {
    public msg: Msg;
    public rootmsg: boolean;
    public refmsgid: string;

    constructor (msg: Msg, rootmsg: boolean, refmsgid: string) {
        this.msg = msg;
        this.rootmsg = rootmsg;
        this.refmsgid = refmsgid;
    }
}

export class msgreq {
    public route: string;

    constructor (route: string) {
        this.route = route;
    }
}

export class FAQ {
    public question: string;
    public answer: string;

    constructor (question: string, answer: string) {
        this.question = question;
        this.answer = answer;
    }
}

export class Cookie {
    public cookie_name: string;
    public is_cookie_enabled: boolean;
    public is_neccessary: boolean;

    constructor (cookie_name: string, is_cookie_enabled: boolean, is_neccessary: boolean) {
        this.cookie_name = cookie_name;
        this.is_cookie_enabled = is_cookie_enabled;
        this.is_neccessary = is_neccessary;
    }
}

export class CookieType {
    public cookie_description: string;
    public cookie_type_name: string;
    public is_enabled: boolean;
    public cookies: Cookie[];
    public is_neccessary: boolean;

    constructor (cookie_description: string, cookie_type_name: string, is_enabled: boolean, is_neccessary: boolean, cookies: Cookie[]) {
        this.cookie_description = cookie_description;
        this.cookies = cookies;
        this.cookie_type_name = cookie_type_name;
        this.is_neccessary = is_neccessary;
        this.is_enabled = is_enabled;
    }
}