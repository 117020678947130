import { Article } from '@data/classes';

export const suggestArticles: Article[] = [
/* 0 */    { title: "Allgemeine Infos zum Aufnahmetest", description: "Was muss ich zum Psychologie-Aufnahmetest 2019 wissen?", img_name: "infos.jpeg", article_link: "/infos" },
/* 1 */    { title: "Tipps zur Vorbereitung", description: "Wie bereite ich mich optimal auf den Aufnahmetest für Psychologie vor?", img_name: "learning.jpeg", article_link: "/vorbereitung" },
/* 2 */    { title: "Städte und Unis", description: "Wie unterscheiden sich Städte und Unis in Österreich?", img_name: "universities.jpeg", article_link: "/universitaeten" },
/* 3 */    { title: "Die wichtigsten Infos zum Aufnahmetest", description: "Hier erfährst Du wie Du Dich Schritt für Schritt für den Aufnahmetest anmeldest.", img_name: "wichtigste_infos.jpeg", article_link: "/infos/wichtigste-infos" },
/* 4 */    { title: "Aufbau und Inhalte des Aufnahmetests", description: "Warum gibt es einen Aufnahmetest? Wie läuft der Test ab und was gibt es sonst noch zu beachten? Das erfährst Du hier.", img_name: "aufbau_inhalte_2.jpeg", article_link: "/infos/aufbau-inhalte" },
/* 5 */    { title: "Anmeldung zum Psychologie-Aufnahmetest", description: "Du fragst Dich, wie der Aufnahmetest genau aufgebaut ist und welche Inhlate im Einzelnen abgefragt werden? Dann findest Du hier die Antworten.", img_name: "anmeldung1.jpeg", article_link: "/infos/anmeldung-test" },
/* 6 */    { title: "Anleitung zur Vorbereitung", description: " Wie bereite ich mich am besten auf den Aufnahmetest für das Psychologiestudium vor? Was sollte ich dabei genau beachten? Keine Sorge – alle wichtigen Hinweise gibt es hier!", img_name: "vorbereitung1.jpeg", article_link: "/vorbereitung/anleitung-vorbereitung" },
/* 7 */    { title: "Buch-Empfehlungen", description: "Sollte ich für den Aufnahmetest bestimmte Bücher kaufen? Welche Bücher sind für die Vorbereitung auf den Test sinnvoll? Welche Inhalte haben die Bücher? In diesem Artikel bekommst Du den nötigen Durchblick!", img_name: "buchempfehlungen1.jpeg", article_link: "/vorbereitung/buch-empfehlungen" },
/* 8 */    { title: "Erfahrungen anderer Studierender", description: " Was berichten eigentlich andere Studierende über den Aufnahmetest? Welche Erfahrungen haben sie gemacht und welche Empfehlungen zur Vorbereitung haben sie? Wir haben für Dich ein paar hilfreiche Erfahrungsberichte von Psychologie-Studierenden zusammengestellt.", img_name: "erfahrungen.jpeg", article_link: "/vorbereitung/erfahrungen-studierende" },
/* 9 */    { title: "Wien", description: "Wien – die schöne Hauptstadt Österreichs bietet die meisten Studienplätze in Psychologie, aber wird auch von den meisten Bewerbern umkämpft. Alles Wichtige, was Du zum Aufnahmetest, zum Studium und zur Stadt Wien wissen musst, erfährst Du hier, kompakt zusammengetragen.", img_name: "wien.jpeg", article_link: "/universitaeten/wien" },
/* 10 */   { title: "Innsbruck", description: "Innsbruck – auch in der Hauptstadt des Bundeslandes Tirol kannst Du Dich für den Psychologie-Aufnahmetest bewerben. Doch wie ist eigentlich die Stadt? Wie finden Studierende die Uni und was gibt es beim Aufnahmetest hier zu beachten? Alle Antworten findest Du hier", img_name: "innsbruck.jpeg", article_link: "/universitaeten/innsbruck" },
/* 11 */   { title: "Klagenfurt", description: "An der Universität in Klagenfurt gibt es für das Psychologiestudium einen therapeutischen Schwerpunkt. Darum unterscheidet sich hier der Aufnahmetest von dem, der anderen Städte. Damit du bestens über den Aufnahmetest in Klagenfurt informiert bist, findest Du hier alles Wichtige zusammengetragen.", img_name: "klagenfurt.jpeg", article_link: "/universitaeten/klagenfurt" },
/* 12 */   { title: "Salzburg", description: "Salzburg – die wunderschöne Stadt an der Salzach hat zukünftigen Psycholoige-Studierenden, die den Aufnahmetest bestehen, viel zu bieten! Doch wie ist eigentlich das Studentenleben dort? Und welche Besonderheiten gibt es an der Uni? Alles Wichtige gibt es hier im Überblick.", img_name: "salzburg.jpeg", article_link: "/universitaeten/salzburg" },
/* 13 */   { title: "Graz", description: "Auch in der Studentenstadt Graz kannst Du den begehrten Studiengang Psychologie absolvieren. Damit Du einen Überblick über den Aufnahmetest, das Leben und die Uni in Graz erhältst, haben wir für Dich den folgenden Artikel geschrieben.", img_name: "graz.jpeg", article_link: "/universitaeten/graz" },
/* 14 */   { title: "Aufnahmetest Simulation", description: "Teste dein Wissen und deine Vorbreitung mit unserer Aufnahmetest-Simulation.", img_name: "test_simulation_1.jpg", article_link: "/test/simulation" },
  ];