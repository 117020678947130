import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/* Components */
import { MainsuggestboxComponent } from './components/mainsuggestbox/mainsuggestbox.component';
import { MainsuggestboxDesktopComponent } from './components/mainsuggestbox/mainsuggestbox-desktop/mainsuggestbox-desktop.component';
import { MainsuggestboxMobileComponent } from './components/mainsuggestbox/mainsuggestbox-mobile/mainsuggestbox-mobile.component';
import { ArticlecontentboxComponent } from './components/articlecontentbox/articlecontentbox.component';
import { ScolluparrowComponent } from './components/scolluparrow/scolluparrow.component';
import { LineclampComponent } from './components/lineclamp/lineclamp.component';
import { ArticlesuggestboxComponent } from './components/articlesuggestbox/articlesuggestbox.component';
import { ArticlesuggestboxDesktopComponent } from './components/articlesuggestbox/articlesuggestbox-desktop/articlesuggestbox-desktop.component';
import { ArticlesuggestboxMobileComponent } from './components/articlesuggestbox/articlesuggestbox-mobile/articlesuggestbox-mobile.component';

import { TopbarDirective } from './directives/topbar.directive';
import { ScrollupDirective } from './directives/scrollup.directive';
import { StickycontentsDirective } from './directives/stickycontents.directive';
import { MouseMoveDirective } from './directives/mouse-move.directive';
import { AnimateOnScrollDirective } from './directives/animate-on-scroll.directive';
import { FaqboxComponent } from './components/faqbox/faqbox.component';
import { DropdownboxComponent } from './components/dropdownbox/dropdownbox.component';
import { DropdownboxDesktopComponent } from './components/dropdownbox/dropdownbox-desktop/dropdownbox-desktop.component';
import { DropdownboxMobileComponent } from './components/dropdownbox/dropdownbox-mobile/dropdownbox-mobile.component';
import { FaqboxDesktopComponent } from './components/faqbox/faqbox-desktop/faqbox-desktop.component';
import { FaqboxMobileComponent } from './components/faqbox/faqbox-mobile/faqbox-mobile.component';
import { CommentsectionComponent } from './components/commentsection/commentsection.component';
import { CommentboxComponent } from './components/commentbox/commentbox.component';
import { CommentinputComponent } from './components/commentinput/commentinput.component';
import { CommentsectionDesktopComponent } from './components/commentsection/commentsection-desktop/commentsection-desktop.component';
import { CommentsectionMobileComponent } from './components/commentsection/commentsection-mobile/commentsection-mobile.component';
import { CommentinputDesktopComponent } from './components/commentinput/commentinput-desktop/commentinput-desktop.component';
import { CommentinputMobileComponent } from './components/commentinput/commentinput-mobile/commentinput-mobile.component';
import { CommentboxDesktopComponent } from './components/commentbox/commentbox-desktop/commentbox-desktop.component';
import { CommentboxMobileComponent } from './components/commentbox/commentbox-mobile/commentbox-mobile.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [CommonModule, MatExpansionModule, ReactiveFormsModule, MatInputModule, MatButtonModule,MatIconModule, RouterModule],
    providers: [],
    declarations: [TopbarDirective, ScrollupDirective, StickycontentsDirective, MouseMoveDirective, AnimateOnScrollDirective, MainsuggestboxComponent, MainsuggestboxDesktopComponent, MainsuggestboxMobileComponent, ArticlecontentboxComponent, ScolluparrowComponent, LineclampComponent, ArticlesuggestboxComponent, ArticlesuggestboxDesktopComponent, ArticlesuggestboxMobileComponent, FaqboxComponent, DropdownboxComponent, DropdownboxDesktopComponent, DropdownboxMobileComponent, FaqboxDesktopComponent, FaqboxMobileComponent, CommentsectionComponent, CommentboxComponent, CommentinputComponent, CommentsectionDesktopComponent, CommentsectionMobileComponent, CommentinputDesktopComponent, CommentinputMobileComponent, CommentboxDesktopComponent, CommentboxMobileComponent],
    exports: [TopbarDirective, ScrollupDirective, StickycontentsDirective, MouseMoveDirective, AnimateOnScrollDirective, MainsuggestboxComponent, MainsuggestboxDesktopComponent, MainsuggestboxMobileComponent, ArticlecontentboxComponent, ScolluparrowComponent, LineclampComponent, ArticlesuggestboxComponent, ArticlesuggestboxDesktopComponent, ArticlesuggestboxMobileComponent, FaqboxComponent, DropdownboxComponent, DropdownboxDesktopComponent, DropdownboxMobileComponent, FaqboxDesktopComponent, FaqboxMobileComponent, CommentsectionComponent, CommentboxComponent, CommentinputComponent, CommentsectionDesktopComponent, CommentsectionMobileComponent, CommentinputDesktopComponent, CommentinputMobileComponent, CommentboxDesktopComponent, CommentboxMobileComponent],
    bootstrap: []
})
export class SharedModule { }