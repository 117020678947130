import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';

import { map } from 'rxjs/operators';
import { PostMsg } from '@data/classes';

import { environment } from '@environments/environment'

@Injectable({
  providedIn: 'root'
})
export class MongdbService {

  constructor(private http: Http) {
  }

  getMsgs(route: string) {
    let data = { route: route };
    return this.http.get('http://' + environment.expressip + '/api/getMsgs', { params: data }).pipe(map((response: Response) => response.json()))
  }

  saveMsg(msg: PostMsg) {
    return this.http.post('http://' + environment.expressip + '/api/saveMsg', msg).pipe(map((response: Response) => response.json()))
  }
}
